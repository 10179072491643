import React, { Fragment } from 'react';
import { findKey, forEach, get, isEmpty, isNumber } from 'lodash';
import dateFormat from 'dateformat';

/* Utils */
import { random } from 'client/utils/seed-randomizers';
import { isNew } from 'client/site-modules/shared/utils/inventory-utils/is-new';
import { isUsed as isUsedVehicle } from 'site-modules/shared/utils/inventory-utils/is-used';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getMetricsHeaders } from 'client/utils/metrics-header';
import { prepareLimitMessages } from 'site-modules/shared/utils/calculator/form-helper';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { EdmundsAPI } from 'client/data/api/api-client';
import { logger } from 'client/utils/isomorphic-logger';

/* Constants */
import {
  CALCULATOR_TYPE_LEASE,
  CALCULATOR_TYPE_LOAN,
  SHARED_PAYMENTS,
  LIMIT_TRADE_IN,
  LIMIT_DOWN_PAYMENT,
  CREDIT_SCORE_VALUES,
  CREDIT_SCORE_EXCELLENT,
  TOOLTIPS_TEXT,
  DEFAULT_LEASE_DOWN_PAYMENT_LOW,
} from 'site-modules/shared/constants/calculator/calculator';

import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { CALCULATOR_EVENTS } from 'site-modules/shared/constants/calculator/events';
import {
  INCENTIVES_SECTIONS,
  IncentiveTooltipInfo,
} from 'site-modules/shared/components/incentives/incentive-tooltip-info/incentive-tooltip-info';
import { INCENTIVE_NAMES } from 'site-modules/shared/constants/inventory/price-analysis';

function getTooltipWithIncentives(incentives, additionalTooltipContent) {
  return {
    text: (
      // eslint-disable-next-line react/jsx-filename-extension
      <Fragment>
        {TOOLTIPS_TEXT.VEHICLE_PRICE.text}
        {incentives.map(incentive => {
          const { name, restrictions } = incentive;

          if (!restrictions) return null;

          const incentiveName = INCENTIVE_NAMES[name] || name;
          return (
            <Fragment key={`${Math.ceil(random() * Number.MAX_SAFE_INTEGER)}`}>
              <div className="fw-bold xsmall mt-0_5">{incentiveName}</div>
              <IncentiveTooltipInfo {...incentive} />
            </Fragment>
          );
        })}
        {additionalTooltipContent && <div className="mt-1">{additionalTooltipContent}</div>}
      </Fragment>
    ),
    placement: 'bottom',
  };
}

export const INFO_TOOLTIP = {
  USED_LOAN: {
    subtitle: "Dealer's Price",
    tooltip: () => ({
      text: `Vehicle Price should be the negotiated sales price you expect to pay for the vehicle. These calculations
    use the list price provided by the dealership.`,
      placement: 'bottom',
    }),
  },
  NEW_LEASE_LOAN: {
    subtitle: 'Estimate based on Edmunds Suggested Price (before incentives)',
    tooltip: (incentives, additionalTooltipContent) => getTooltipWithIncentives(incentives, additionalTooltipContent),
  },
  SPECIAL_DEALER_INFO: {
    subtitle:
      "MSRP is the Manufacturer's Suggested Retail Price (before incentives) and is typically not the final sales price for most cars.",
    tooltip: () => ({
      text:
        "The MSRP shown is the Manufacturer's Suggested Retail Price (before rebates) for this vehicle as provided to Edmunds by the dealer. The price you ultimately pay may vary, depending on market conditions in your area, and is subject to negotiation between you and the dealer.",
      placement: 'bottom',
    }),
  },
};

export function getTooltipTextWithIncentives({
  incentives,
  additionalTooltipContent = '',
  vehicleType,
  calculatorType,
}) {
  if (calculatorType === CALCULATOR_TYPE_LOAN && isUsedVehicle(vehicleType)) {
    return INFO_TOOLTIP.USED_LOAN.tooltip().text;
  }

  const { restrictions: restrictionsLabel, bonus, start, end } = INCENTIVES_SECTIONS;
  const SECTIONS_MAPPING = {
    rebateAmount: {
      label: bonus,
      getValue: rebateAmount => rebateAmount && formatPriceString(rebateAmount),
    },
    startDate: {
      label: start,
      getValue: startedDate => dateFormat(new Date(startedDate), 'mm/dd/yyyy'),
    },
    endDate: {
      label: end,
      getValue: expiredDate => dateFormat(new Date(expiredDate), 'mm/dd/yyyy'),
    },
  };
  const incentivesText = incentives.map(incentive => {
    const { name, restrictions } = incentive;
    if (!restrictions) return null;
    const incentiveName = INCENTIVE_NAMES[name] || name;
    const incentiveSections = [];

    forEach(SECTIONS_MAPPING, (section, key) => {
      const val = incentive[key];
      if (val) {
        incentiveSections.push(`${section.label} ${section.getValue(val)}`);
      }
    });
    return `${restrictions} ${incentiveName} ${incentiveSections.join(' ')}`;
  });
  const restrictions = !isEmpty(incentivesText) ? restrictionsLabel : '';

  return `${TOOLTIPS_TEXT.VEHICLE_PRICE.text} ${restrictions} ${incentivesText} ${additionalTooltipContent}`;
}

/**
 * Defines based on predefined calculator type and payments type which types of calculators can be shown
 * @param {String} vinType
 * @return {Array} Array of calculator types
 *
 * example:
 * defineDisplayedCalculators('New') => ['lease', 'loan']
 */
export function defineDisplayedCalculators(vinType) {
  const displayedCalculators = [CALCULATOR_TYPE_LOAN];

  if (isNew(vinType)) {
    displayedCalculators.push(CALCULATOR_TYPE_LEASE);
  }

  return displayedCalculators;
}

/**
 * Defines opened calculator type
 * @param {String} forcedCalculatorType
 * @param {String} vinType
 * @param {String} selectedPaymentType
 * @return {String}
 *
 * Example:
 * defineOpenedCalculator('', 'New', 'lease') => 'lease'
 * defineOpenedCalculator('', 'New') => 'loan'
 */
export function defineOpenedCalculator(forcedCalculatorType, vinType, selectedPaymentType) {
  const displayedCalculators = defineDisplayedCalculators(vinType);

  if (displayedCalculators.indexOf(forcedCalculatorType) !== -1) return forcedCalculatorType;

  if (displayedCalculators.indexOf(selectedPaymentType) !== -1) return selectedPaymentType;

  return displayedCalculators[0];
}

/**
 * Checks whether payment is shared.
 * @param {string} name
 * @returns {boolean}
 */
export function isSharedPayment(name) {
  return SHARED_PAYMENTS.includes(name);
}

export function getPaymentFeedbackParams({
  payments,
  taxesAndFeesZipCode,
  calculatorType,
  type = PUB_STATES_LOWERCASE.NEW,
  incentives,
  additionalTooltipContent,
  creditTier: { name, rate = '' },
}) {
  return {
    ...payments,
    taxesAndFeesZipCode,
    paymentType: `${type.toLowerCase()}+${calculatorType}`,
    priceTooltipText: getTooltipTextWithIncentives({
      incentives,
      vehicleType: type,
      calculatorType,
      additionalTooltipContent,
    }),
    creditTier: `${name}+${rate}`,
  };
}

export const getTradeInCopy = hasMMYAppraisalLabel =>
  hasMMYAppraisalLabel ? 'Appraise another vehicle >' : "What's my car worth?";

export const getAppLiedLimitAmount = (offer, field) => {
  const limit = get(offer, 'limitCriteria.limits', []).find(({ paymentType }) => paymentType === field);
  return limit && Number(limit.appliedAmount.toFixed());
};

export const trackCalculatorLimit = ({ cur, prev }, field, calculatorType) => {
  const prevLimit = getAppLiedLimitAmount(prev, field);
  const limit = getAppLiedLimitAmount(cur, field);

  if (isNumber(limit) && prevLimit !== limit) {
    EventToolbox.fireCustomEvent(CALCULATOR_EVENTS.TRIGGER_LIMIT, {
      value: limit,
      calculatorType,
      field,
    });
  }
};

export const trackCalculatorLimits = (offers, fields, calculatorType) => {
  fields.forEach(field => trackCalculatorLimit(offers, field, calculatorType));
};

export function calculateLimits({
  salesPrice,
  msrp,
  isPurchase,
  isUsed,
  tradeIn,
  residual,
  styleId,
  zipCode,
  pageName,
  venomVersion,
}) {
  const apiPath = isPurchase ? '/calculators/v2/loanpayment/' : '/calculators/v2/leasepayment/';
  const urlParams = isPurchase
    ? `?salesPrice=${salesPrice}&msrp=${msrp}`
    : `?msrp=${msrp}&residualValuePercentage=${residual * 100}&benchmarkPrice=${salesPrice}`;
  const url = `${apiPath}${urlParams}&zipCode=${zipCode}&withTaxesAndFees=false&styleId=${styleId}&tradeIn=${tradeIn}&downPayment=0&applyLimits=true&calculatorType=${
    isUsed ? 'Used' : 'New'
  }&view=custom,fieldsInclude:limitCriteria`;

  try {
    return EdmundsAPI.fetchJson(url, {
      headers: getMetricsHeaders(`${isPurchase ? 'loanPayments' : 'leasePayments'}`, pageName, venomVersion),
    });
  } catch (e) {
    return {};
  }
}

/**
 * Gets validation messages for purchase and lease trade-in and down payment values..
 * @returns {object}
 */
export function getValidationFormMessages(limitCriteria, publicationState) {
  const responseErrors = prepareLimitMessages(limitCriteria, publicationState);
  return {
    tradeInValidationError: get(responseErrors, LIMIT_TRADE_IN, ''),
    downPaymentValidationError: get(responseErrors, LIMIT_DOWN_PAYMENT, ''),
  };
}

/**
 * Gets current payment.
 * If we input custom vehicle price, then we have different payments for calculator and price summary widget.
 * If the custom vehicle price is not define, then the payments for calculator and price summary widget will be the same.
 * @returns {object}
 */
export function getCurrentPayment(payments, isWidget = false) {
  if (isWidget) {
    return Array.isArray(payments) ? payments[1] : payments;
  }

  return Array.isArray(payments) ? payments[0] : payments;
}

/**
 * Returns credit score name by credit score value or 'Unknown'.
 * @param {number} creditScore
 * @return {string}
 */
export function getCreditScoreName(creditScore) {
  if (isNumber(creditScore)) {
    return creditScore >= CREDIT_SCORE_VALUES[CREDIT_SCORE_EXCELLENT].max
      ? CREDIT_SCORE_EXCELLENT
      : findKey(CREDIT_SCORE_VALUES, score => creditScore <= score.max && creditScore >= score.min);
  }

  return 'Unknown';
}

export const LOCAL_STORAGE_KEY = 'calcZipCode';

/**
 * Returns zipCode if zipCode exists in local storage and the session id from local storage is equal to the current session id. Otherwise, return null.
 * @param storage
 * @param {string | number} currentSessionId
 * @return {string | number | null}
 */
export function extractSavedCalculatorZip(storage, currentSessionId) {
  const localStorageData = storage.get(LOCAL_STORAGE_KEY);
  const zipCode = get(localStorageData, 'zipCode');
  const sessionId = get(localStorageData, 'sessionId');

  if (zipCode && sessionId === currentSessionId) {
    return zipCode;
  }

  storage.remove(LOCAL_STORAGE_KEY);
  return null;
}

export const getDefaultLeaseDownPayment = vehicle =>
  get(vehicle, 'prices.estimateLeasePromise.downPayment') || DEFAULT_LEASE_DOWN_PAYMENT_LOW;

export async function getLeaseTerms({ styleId, zipCode, creditScore }) {
  try {
    const leaseTermsApiUrl = `/car-buying/v2/lease-terms/?styleid=${styleId}&zip=${zipCode}&creditscore=${creditScore}`;
    const leaseTerms = await EdmundsAPI.fetchJson(leaseTermsApiUrl);

    return leaseTerms.terms;
  } catch (err) {
    logger('error', `Lease terms calculate error - ${err}`);
    return {};
  }
}
