import { keyBy, isNil, get, set, difference, concat } from 'lodash';
// eslint-disable-next-line venom/no-venom-api-calls
import { EdmundsAPI, VenomApi } from 'client/data/api/api-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { getProfile } from 'client/site-modules/shared/components/profile/idm/idm';
import {
  getAlerts,
  getAlertsVins,
  getUnViewedAlerts,
  getUnViewedAlertsVins,
  getSortedValidPriceDrops,
  ALERT_TYPES_MAPPING,
  getAlertsPath,
} from 'client/site-modules/shared/components/profile/price-drop-alerts/alerts-utils';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { VisitorModel } from 'client/data/models/visitor';
import { VisitorHistoryModel } from 'client/data/models/visitor-history';
import { logger } from 'client/utils/isomorphic-logger';
import { getUsurpImages } from 'site-modules/shared/utils/inventory/srp-images';

import { fetchImages } from './profile-image-helper';
import { PROFILE_CONSTANTS, IDM_METHODS_MAPPING, IDM_METHODS } from './profile-constants';
import {
  transformSavedData,
  transformSavedDataWithAlerts,
  getIdmCallback,
  getZipAndIdmValues,
  waitForAppraisals,
  createAppraisalPromiseObj,
  mapAppraisalData,
  mapInventoryData,
  getRecentlyViewedVins,
  transformRecentlyViewedVins,
  applyAlerts,
  filterSavedSearches,
  getVinsRequest,
} from './profile-utils';

export const ERROR_MESSAGES = {
  photoError: 'Error while fetching Profile vin photo data:',
  statsError: 'Error while fetching Profile vin statistics data:',
  inventoryError: 'Error while fetching Profile inventory data:',
  idmError: 'Error while fetching Profile IDM data:',
};

async function resolvePath(context, resolver) {
  const shouldResolve = await context.resolveValue('insider', FeatureFlagModel);
  if (shouldResolve) {
    return resolver();
  }
  return context.abort();
}

/**
 *  WARNING: IMPORTANT
 *  ALL PATH SHOULD BE RESOLVED USING `resolvePath`  function
 */
export const UserProfileModel = createModelSegment('profile', [
  {
    path: 'data.savedVINs.currentVinList',
  },
  {
    path: 'data.savedVINs.requestVinList',
  },
  {
    path: 'data.savedVINs.itemsPerPage.{itemsPerPage}.vinList',
    async resolve({ itemsPerPage }, context) {
      const [location, requestVinList, currentVinList] = await Promise.all([
        context.resolveValue('location', VisitorModel),
        context.resolveValue('data.savedVINs.requestVinList'),
        context.resolveValue('data.savedVINs.currentVinList', UserProfileModel, false),
      ]);

      const vinList = difference(requestVinList, currentVinList);
      await context.updateValue('data.savedVINs.currentVinList', concat(currentVinList, vinList));

      const queryString = getVinsRequest({ vin: vinList, pagesize: itemsPerPage, location });
      return vinList.length
        ? withMetrics(EdmundsAPI, context)
            .fetchJson(`/purchasefunnel/v1/srp/inventory/?${queryString}`)
            .then(data => get(data, 'inventories.results', []))
            .catch(error => {
              logger('warn', `${ERROR_MESSAGES.inventoryError} ${error}`);
              return [];
            })
        : [];
    },
  },
  {
    path: 'data.savedVINs.activeVins',
    async resolve(match, context) {
      const idmData = await context.resolveValue('data.idm', UserProfileModel, false).catch(() => ({}));
      const savedVins = get(idmData, 'vehicles.vins', {});
      const vins = Object.keys(savedVins)
        .filter(vin => !savedVins[vin].nonVin)
        .map(vin => vin)
        .join(',');

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ vins }),
        showAPIError: true,
        timeout: 0,
      };

      // eslint-disable-next-line venom/no-venom-api-calls
      return VenomApi.fetchJson('/profile/active-vins/', options);
    },
  },
  {
    path: 'data.savedVINs.itemsPerPage.{itemsPerPage}.pageNum.{pageNum}',
    async resolve(match, context) {
      return resolvePath(context, async () => {
        const itemsPerPage = +match.itemsPerPage;
        let page = +match.pageNum;

        if (!itemsPerPage) return Promise.resolve(PROFILE_CONSTANTS.EMPTY_DATA);
        if (!page) {
          page = 1;
        }

        const idmData = await context.resolveValue('data.idm').catch(error => {
          logger('warn', `${ERROR_MESSAGES.idmError} ${error}`);
          return {};
        });
        const { alerts } = await context.resolveValue(getAlertsPath('PRICE_DROP', false)).catch(error => {
          logger('warn', `${ERROR_MESSAGES.idmError} ${error}`);
          return { alerts: {} };
        });

        const { activeVins } = await context.resolveValue('data.savedVINs.activeVins');

        const sortedDataWithPagination = transformSavedDataWithAlerts(
          get(idmData, 'vehicles.vins', {}),
          alerts,
          activeVins,
          page,
          itemsPerPage
        );
        const vinList = sortedDataWithPagination.map(item => item.vin);

        const savedVins = keyBy(sortedDataWithPagination, 'vin');

        await context.updateValue('data.savedVINs.requestVinList', vinList);
        const inventoryData = await context.resolveValue(`data.savedVINs.itemsPerPage.${itemsPerPage}.vinList`);

        const inventories = Object.values(mapInventoryData(savedVins, keyBy(inventoryData, 'vin')));
        inventories.forEach(inventory => {
          const { photoUrls } = getUsurpImages(inventory, false);
          set(inventory, 'vehicleInfo.photo.defaultPhoto.large.url', photoUrls[0]);
        });
        return inventories;
      });
    },
  },
  {
    path: 'data.alerts.type["{type}"].checkVinAvailability["{checkVinAvailability}"]',
    async resolve({ type, checkVinAvailability }, context) {
      return resolvePath(context, async () => {
        const idmData = await context.resolveValue('data.idm');
        const alerts = get(idmData, 'alerts');
        const alertTypes = ALERT_TYPES_MAPPING[type];
        const alertsByType = getAlerts(alerts, alertTypes);
        const unViewedAlerts = getUnViewedAlerts(alertsByType);
        const unViewedAlertsVins = getUnViewedAlertsVins(alertsByType);
        let newAlerts = [];

        if (checkVinAvailability.match(/true|yes|1/)) {
          let inventories;
          try {
            if (unViewedAlertsVins.length) {
              inventories = await withMetrics(EdmundsAPI, context).fetchJson(
                `/inventory/v5/find/?vin=${unViewedAlertsVins.join(',')}&pagesize=100&pagenum=1&fields=vin`
              );
            } else {
              inventories = { results: PROFILE_CONSTANTS.EMPTY_DATA };
            }
          } catch (e) {
            inventories = { results: PROFILE_CONSTANTS.EMPTY_DATA };
          }

          const inventoryVins = inventories.results.map(inventory => inventory.vin);
          newAlerts = newAlerts.concat(
            unViewedAlerts.filter(alert => inventoryVins.some(vin => vin === alert.alertData.vinReference))
          );
        }

        return { alerts: alertsByType, newAlerts, newAlertsCount: newAlerts.length };
      });
    },
  },
  {
    path: 'data.alerts.type["{type}"].withInventory',
    async resolve({ type }, context) {
      return resolvePath(context, async () => {
        let inventoryWithPhotos;
        const { alerts } = await context.resolveValue(getAlertsPath(type, false));
        const sortedAlerts = getSortedValidPriceDrops(alerts);

        if (!sortedAlerts.length) {
          return { lastAlertsWithInventory: [], alertsWithInventory: [] };
        }

        try {
          const inventory = await withMetrics(EdmundsAPI, context).fetchJson(
            `/inventory/v5/find/?vin=${getAlertsVins(sortedAlerts).join(
              ','
            )}&pagesize=100&pagenum=1&fields=vin,type,vehicleInfo(photo,styleInfo(make,model,year,styleId)),prices(displayPrice,guaranteedPrice),dealerInfo.productFeatures.directDealer`
          );
          inventoryWithPhotos = await fetchImages(
            keyBy(inventory.results, 'vin'),
            context,
            'EVOX',
            'TDS,F,FQ,F34',
            '175',
            'small'
          );
        } catch (e) {
          inventoryWithPhotos = PROFILE_CONSTANTS.EMPTY_DATA;
        }

        const alertsWithInventory = sortedAlerts
          .map(alert => {
            const alertInv = inventoryWithPhotos.find(
              inv => !!alert.alertData && inv.vin === alert.alertData.vinReference
            );
            return {
              ...alert,
              inventoryData: alertInv,
            };
          })
          .filter(({ inventoryData }) => !isNil(inventoryData));

        return {
          lastAlertsWithInventory: alertsWithInventory.slice(0, 3),
          alertsWithInventory,
        };
      });
    },
  },

  {
    path: 'data.savedSearches',
    resolve(match, context) {
      return resolvePath(context, () =>
        context
          .resolveValue('data.idm')
          .then(idm => filterSavedSearches(idm.savedSearches || PROFILE_CONSTANTS.EMPTY_DATA))
      );
    },
  },
  {
    path: 'data.recentlyViewedVins.size["{size}"]',
    async resolve(match, context) {
      return resolvePath(context, async () => {
        const { size } = match;
        let recentlyViewedVins;
        try {
          const data = await Promise.all([
            context.resolveValue('data.idm'),
            context.resolveValue(
              'aggregatedHistory.vinAndModelAggregates.size["100"].isVinOnly["true"]',
              VisitorHistoryModel
            ),
          ]);
          const userSavedVins = data[0];
          const recentlyViewedVinsData = data[1];
          const aggregatedVinHistory = recentlyViewedVinsData.map(item => item.vin);
          const { alerts } = await context.resolveValue(getAlertsPath('VIEWED_VIN_PRICE_DROP', false));
          const vinList = getRecentlyViewedVins(userSavedVins, aggregatedVinHistory, alerts).slice(0, size);
          const vinListLength = vinList.length;
          if (vinListLength === 0) return PROFILE_CONSTANTS.EMPTY_DATA;
          recentlyViewedVins = applyAlerts(transformRecentlyViewedVins(vinList, recentlyViewedVinsData), alerts);
        } catch (e) {
          recentlyViewedVins = PROFILE_CONSTANTS.EMPTY_DATA;
        }

        return recentlyViewedVins;
      });
    },
  },
  {
    path: 'data.appraisalData[{pageNumber}]',
    resolve(match, context) {
      return resolvePath(context, () => {
        let savedAppraisal;

        const pageNumber = +match.pageNumber;
        if (!pageNumber) return Promise.resolve(PROFILE_CONSTANTS.EMPTY_DATA);

        return getZipAndIdmValues(context)
          .then(([zip, idm]) => {
            const sortedDataWithPagination = transformSavedData(
              idm,
              'vehicles.appraisalHistory',
              'id',
              pageNumber,
              PROFILE_CONSTANTS.APPRAISALS_PER_PAGE
            );
            const promiseObj = createAppraisalPromiseObj(sortedDataWithPagination, context, zip);

            savedAppraisal = keyBy(sortedDataWithPagination, 'id');

            return Object.keys(promiseObj).length
              ? waitForAppraisals(promiseObj)
              : Promise.resolve(PROFILE_CONSTANTS.EMPTY_DATA);
          })
          .then(priceValues => mapAppraisalData(savedAppraisal, priceValues))
          .then(appraisalData => fetchImages(appraisalData, context, 'EVOX'));
      });
    },
  },
  {
    path: 'data.idm',
    resolve(match, context) {
      return resolvePath(context, () => getProfile());
    },
    update({ data, visitorAndPageContext, method = IDM_METHODS.UPDATE, uid, checkExisting }, match, context) {
      return resolvePath(context, () => {
        const idmMethod = IDM_METHODS_MAPPING[method];
        const callback = getIdmCallback(method, context);
        // Retrieve updated profile since create/update profile API returns status only
        return idmMethod({ data, visitorAndPageContext, method, uid, checkExisting }).then(callback);
      });
    },
  },
]);
