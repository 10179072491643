import { get, omit } from 'lodash';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';
import { getCalculatedPrices } from 'site-modules/shared/utils/car-buying/calculated-prices';
import { convertIncentives } from 'site-modules/shared/utils/car-buying/submit-lead-receipt-helper';
import { LEASE_FINANCE_METHOD, LOAN_FINANCE_METHOD } from 'site-modules/shared/constants/car-buying';
import { NON_CAPITALIZED_TAXES_AND_FEES_SUM } from 'site-modules/shared/constants/calculator/calculator';
import { getTradeInDetails } from 'site-modules/shared/utils/car-buying/calculator-util';

/**
 * Formats taxes and fees to insider acceptable data format.
 * @param {object} taxes
 * @param {object} fees
 * @returns {{fees: object[], taxes: object[]}}
 */
function formatTaxesAndFees(taxes, fees) {
  return {
    taxes: taxes
      ? Object.keys(taxes).map(key => ({
          name: key,
          amount: taxes[key],
          capitalized: true,
        }))
      : [],
    fees: fees
      ? Object.keys(fees).map(key => ({
          name: key,
          amount: fees[key],
          capitalized: true,
        }))
      : [],
  };
}

/**
 * Maps incentives to insider acceptable data format.
 * @param {object[]} incentives
 * @returns {object[]|array}
 */
function mapIncentives(incentives) {
  return incentives
    ? incentives.map(({ programName, cash, expirationDate, expireDate }) => ({
        name: programName,
        value: cash,
        expiryTs: new Date(expirationDate || expireDate).toISOString(),
      }))
    : [];
}

/**
 * Gets financing terms for loan and lease.
 * @param {boolean} isPurchase
 * @param {number} numberOfMonths
 * @param {number} apr
 * @param {number} downPayment
 * @param {number} annualMileage
 * @returns {object}
 */
function getFinancingTerms({ isPurchase, numberOfMonths, apr, downPayment, annualMileage }) {
  return isPurchase
    ? { loanPreferences: { numberOfMonths, financeRate: apr, downPayment } }
    : { leasePreferences: { numberOfMonths, annualMileage, downPayment } };
}

/**
 * Format deal attributes for single deal selection.
 * @param {object} vehicle
 * @param {object} dealDetails
 * @param {boolean} isPurchase
 * @returns {object}
 */
export function formatDealAttributes(vehicle, dealDetails, isPurchase) {
  const {
    dueAtSigning,
    monthlyPayment,
    paymentWithoutTaxes,
    paymentWithTaxes,
    term: numberOfMonths,
    annualMileage,
    downPayment,
    sellingPrice,
    totalRebate,
    moneyFactor,
    apr,
    residual: residualPercentage,
    incentives,
    storedZipCode,
    preQualified,
    leadId,
    endDateEpochMillis,
    tradeIn,
    storedSelections,
    saveTradeInDetails,
  } = dealDetails;
  const { baseMonthlyPayment } = paymentWithoutTaxes;
  const {
    amountFinanced,
    capitalizedFees,
    securityDeposit = 0,
    capitalizedTaxes,
    nonCapitalizedTaxesAndFees,
  } = paymentWithTaxes;
  const { displayPrice } = vehicle.prices;

  const taxesAndFees = { ...formatTaxesAndFees(capitalizedTaxes, capitalizedFees) };
  taxesAndFees.fees.push({ name: NON_CAPITALIZED_TAXES_AND_FEES_SUM, amount: nonCapitalizedTaxesAndFees });

  const { purchasePrice, marketAdjustment } = getCalculatedPrices({ sellingPrice, totalRebate, msrp: displayPrice });

  let tradeInDetails;

  if (saveTradeInDetails) {
    tradeInDetails = getTradeInDetails({
      selections: storedSelections,
      appliedTradeIn: tradeIn,
      limitCriteria: get(dealDetails, 'limitCriteria', {}),
      pubState: vehicle.type,
    });
  }

  return {
    financing: getFinancingTerms({ isPurchase, numberOfMonths, apr, downPayment, annualMileage }),
    edmundsDeals: [
      {
        vin: vehicle.vin,
        financeMethod: isPurchase ? LOAN_FINANCE_METHOD : LEASE_FINANCE_METHOD,
        zipCode: storedZipCode,
        monthlyPayment,
        baseMonthlyPayment,
        dueAtSigning,
        downPayment,
        numberOfMonths,
        mileage: annualMileage,
        capCost: sellingPrice,
        msrp: displayPrice,
        estimatedPurchasePrice: purchasePrice,
        amountFinanced,
        marketAdjustment,
        moneyFactor,
        apr,
        residualValue: isPurchase ? undefined : displayPrice * residualPercentage,
        residualPercentage,
        totalRebate,
        rebates: mapIncentives(incentives),
        expiryTs: isPurchase ? undefined : new Date(endDateEpochMillis).toISOString(),
        createdTs: getSyncDate(),
        preQualified,
        leadReference: leadId && `#/leads/${leadId}`,
        securityDeposit,
        ...taxesAndFees,
        tradeInValue: tradeIn,
        tradeIn: tradeInDetails,
      },
    ],
  };
}

/**
 * Returns formatted deal attributes for single TED deal.
 * @param {object[]} deals
 * @param {object} selectedLeadTargets
 * @param {string[]} leadIds
 * @param {string} zipCode
 * @returns {object}
 */
export function formatSingleDealAttributes({ deals, selectedLeadTargets, leadIds, zipCode }) {
  const offer = get(deals, '[0].dealOffer');
  const vehicle = get(deals, '[0].inventory', {});
  const tradeInDetails = get(deals, '[0].calculatedData.tradeInDetails');
  const isPurchase = get(offer, 'isPurchase');
  const leadId = get(leadIds, '[0]');

  const {
    dueAtSigning,
    monthlyPayment,
    paymentWithoutTaxes,
    paymentWithTaxes,
    term: numberOfMonths,
    annualMileage,
    downPayment,
    sellingPrice,
    totalRebate,
    moneyFactor,
    apr,
    residual: residualPercentage,
    incentives,
    endDateEpochMillis,
    tradeIn,
  } = offer;
  const { baseMonthlyPayment } = paymentWithoutTaxes;
  const {
    amountFinanced,
    capitalizedFees,
    securityDeposit = 0,
    capitalizedTaxes,
    nonCapitalizedTaxesAndFees,
  } = paymentWithTaxes;
  const { displayPrice } = vehicle.prices;

  const taxesAndFees = { ...formatTaxesAndFees(capitalizedTaxes, capitalizedFees) };
  taxesAndFees.fees.push({ name: NON_CAPITALIZED_TAXES_AND_FEES_SUM, amount: nonCapitalizedTaxesAndFees });

  const { purchasePrice, marketAdjustment } = getCalculatedPrices({ sellingPrice, totalRebate, msrp: displayPrice });

  return {
    financing: getFinancingTerms({ isPurchase, numberOfMonths, apr, downPayment, annualMileage }),
    edmundsDeals: [
      {
        vin: vehicle.vin,
        financeMethod: isPurchase ? LOAN_FINANCE_METHOD : LEASE_FINANCE_METHOD,
        zipCode,
        monthlyPayment,
        baseMonthlyPayment,
        dueAtSigning,
        downPayment,
        numberOfMonths,
        mileage: annualMileage,
        capCost: sellingPrice,
        msrp: displayPrice,
        estimatedPurchasePrice: purchasePrice,
        amountFinanced,
        marketAdjustment,
        moneyFactor,
        apr,
        residualValue: isPurchase ? undefined : displayPrice * residualPercentage,
        residualPercentage,
        totalRebate,
        rebates: mapIncentives(incentives),
        expiryTs: isPurchase ? undefined : new Date(endDateEpochMillis).toISOString(),
        createdTs: getSyncDate(),
        preQualified: false,
        leadReference: leadId && `#/leads/${leadId}`,
        securityDeposit,
        ...taxesAndFees,
        tradeInValue: tradeIn,
        tradeIn: tradeInDetails,
      },
    ],
    leadTargets: leadIds.map((id, ind) => ({
      leadId: id,
      inventory: get(selectedLeadTargets, `[${ind}].inventory`),
      vehicleParams: { cbpStarted: true },
    })),
  };
}
/**
 * Format deal attributes for multi deal selection.
 * @param {object[]} deals
 * @param {object[]} leadTargets
 * @param {string[]} leadIds
 * @param {string} zipCode
 * @returns {object}
 */
export function formatMultiDealAttributes({ deals, selectedLeadTargets, leadIds, zipCode }) {
  // get first offer since all offers contains the same information about selected terms
  const firstOffer = get(deals, '[0].dealOffer');
  // determine if passed offers are purchase offers (loan) or lease offers
  const isPurchase = get(firstOffer, 'offerType', '').includes(LOAN_FINANCE_METHOD.toUpperCase());
  // set first lead target inventory as fallback inventory if leadIds < selectedLeadTargets (case when several deals for
  // one vehicle)
  const firstLeadTargetInventory = get(selectedLeadTargets, '[0].inventory', {});

  return {
    financing: getFinancingTerms({
      isPurchase,
      numberOfMonths: firstOffer.term,
      apr: firstOffer.annualPercentageRate,
      downPayment: firstOffer.downPayment,
      annualMileage: firstOffer.annualMileage,
    }),
    edmundsDeals: deals.map(({ inventory, dealOffer }, ind) => {
      const { vin } = inventory;
      const leadId = leadIds[ind];
      const {
        offerType,
        taxes,
        fees,
        displayPrice,
        residualAmount,
        programStartDate,
        programEndDate,
        limitCriteria,
        sellingPrice,
        incentives,
        tradeIn,
        totalRebate,
        annualPercentageRate,
        annualMileage,
        destinationFee,
        term,
        tmv,
        creditScore,
        dealType,
        ...offer
      } = dealOffer;

      const { purchasePriceAfterRebates } = getCalculatedPrices({
        sellingPrice: tmv || displayPrice,
        incentives,
        msrp: displayPrice,
      });

      return {
        ...offer,
        vin,
        zipCode,
        totalRebate,
        mileage: annualMileage,
        numberOfMonths: term,
        apr: annualPercentageRate,
        financeMethod: isPurchase ? LOAN_FINANCE_METHOD : LEASE_FINANCE_METHOD,
        capCost: sellingPrice,
        msrp: displayPrice,
        estimatedPurchasePrice: purchasePriceAfterRebates,
        residualValue: residualAmount,
        rebates: mapIncentives(convertIncentives(incentives)),
        expiryTs: programEndDate ? new Date(programEndDate).toISOString() : undefined,
        createdTs: getSyncDate(),
        preQualified: false,
        leadReference: leadId && `#/leads/${leadId}`,
        ...formatTaxesAndFees(taxes, omit(fees, ['dmvFeesItemized'])),
        tradeInValue: tradeIn,
      };
    }),
    // mark lead target as cbp (ted) lead target
    leadTargets: leadIds.map((leadId, ind) => ({
      leadId,
      inventory: get(selectedLeadTargets, `[${ind}].inventory`, firstLeadTargetInventory),
      vehicleParams: { cbpStarted: true },
    })),
  };
}

export const formatAdjustedDealAttributes = deals => data => formatSingleDealAttributes({ deals, ...data });
