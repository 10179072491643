import { omit, isEmpty, merge } from 'lodash';
import { getCurrentUser, signOut } from 'site-modules/shared/components/profile/firebase-auth';
import { signInAnonymously } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import { idmAPI, idmVanillaForumAPI } from 'client/data/api/api-client';
import { IDM_METHODS } from 'client/data/models/profile/profile-constants';
import {
  setAnalyticsData,
  setAnalyticsDataForSignIn,
  populateLastLandingPageVisitTs,
  populateSyncAttributes,
} from './idm-analytics';

const NO_CACHE_HEADERS = { Pragma: 'no-cache', 'Cache-Control': 'no-cache' };
const UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS = ['prospectCreateTs', 'prospectEntryPoint', 'prospectEntryPointDetail'];
const SHOULD_POPULATE_PROSPECT_ENTRY = true;

/**
 * Get request options for all IDM requests
 * @param {Object} [body] - request body.
 * @param {String} [method] - request method.
 * @param {String} [aid] - anonymous id.
 * @returns {Object} promise.
 */
export const getReqOptions = (body, method = 'GET', aid) => {
  const headers = {
    ...(method === 'GET' ? NO_CACHE_HEADERS : {}),
    ...(method === 'PUT' && aid ? { 'x-aid': aid } : {}),
  };
  return getCurrentUser()
    .then(currentUser => currentUser && currentUser.getIdToken())
    .then(token => ({ headers: { ...headers, authorization: token }, method, body: JSON.stringify(body) }));
};

/**
 * Common wrapper for IDM PUT method. Omits prospect details because this is an update call.
 * @param {Object} data - source object with user profile data.
 * @param {String} aid - anonymous id
 * @param {boolean} skipOmitProspectEntry
 * @returns {Object} promise.
 */
export const update = (data, aid, skipOmitProspectEntry) => {
  const userData = data;
  if (!isEmpty(userData.intelligentMessaging) && !skipOmitProspectEntry) {
    userData.intelligentMessaging = omit(userData.intelligentMessaging, UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS);
  }
  return getReqOptions(userData, 'PUT', aid).then(options => idmAPI.fetchJson('/delta?isTransactional=true', options));
};

/**
 * Common wrapper for IDM PUT method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @param {String} aid - anonymous id
 * @returns {Object} promise.
 */
export const create = data =>
  getReqOptions(data, 'PUT').then(options => idmAPI.fetchJson('/?isTransactional=true', options));

/**
 * Common wrapper for IDM DELETE method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @returns {Object} promise.
 */
export const remove = () => getReqOptions(undefined, 'DELETE').then(options => idmAPI.fetchJson('/', options));

/**
 * Gets user profile from IDM
 * @returns {Object} promise.
 */
export const getProfile = () =>
  getReqOptions()
    .then(options => idmAPI.fetchJson('', options))
    .then(data => data.result);

/**
 * Generates vanilla_sso signature string for vanilla forums authentication
 * @returns {String} vanilla_sso signature string
 */
export const getVanillaSSOSignatureString = () =>
  getReqOptions()
    .then(options => idmVanillaForumAPI.fetchJson('/authtoken', options))
    .then(({ result: { ssoToken } }) => ssoToken);

export function updateLastLandingTs() {
  return update(populateLastLandingPageVisitTs());
}

/**
 * Save or delete user data in IDM (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @param {String} [method] - supported methods: UPDATE, DELETE.
 * @returns {Object} promise.
 */
export function updateProfileData({ data = {}, visitorAndPageContext, method }) {
  const userData = data;
  userData.recordType = method === IDM_METHODS.DELETE ? 'Delete' : 'Upsert';

  if (method !== IDM_METHODS.DELETE) {
    setAnalyticsData(userData, visitorAndPageContext);
  } else {
    merge(userData, populateSyncAttributes());
  }

  return update(userData);
}

/**
 * Create/Update user data in IDM when user register / signed in
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @param {String} uid
 * @param {boolean} checkExisting
 * @returns {Object} promise.
 */
export function updateLoginProfile({ data = {}, visitorAndPageContext, uid, checkExisting } = {}) {
  // if email/password provider is used we can skip the check, anonymous is converted to registered
  // if social provider is used or SignUp action (there is no anonymous), we need to check the profile, anonymous is not converted to registered
  return (checkExisting ? getProfile() : Promise.resolve({ hasProfile: true })).then(hasData => {
    const isNewProfile = isEmpty(hasData);

    const userData = data;
    userData.recordType = isNewProfile ? 'Aggregate' : 'Upsert';
    // Sends prospectCreateTs only if the user profile was not found in IDM
    setAnalyticsDataForSignIn(userData, visitorAndPageContext, isNewProfile);

    return update(userData, uid, isNewProfile);
  });
}

/**
 * Creates anonymous user with data (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function createAnonymousProfile({ data = {}, visitorAndPageContext } = {}) {
  const userData = data;
  userData.recordType = 'Upsert';
  setAnalyticsDataForSignIn(userData, visitorAndPageContext, SHOULD_POPULATE_PROSPECT_ENTRY);

  return create(userData);
}

/**
 * Signs out of current profile, signs in and creates anonymous user
 * @param {Object} data - Data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function signInAndCreateAnonymousProfile({
  data: { dataToCreate, signInAnonymouslyTracking },
  visitorAndPageContext,
}) {
  return signOut()
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile({ data: dataToCreate, visitorAndPageContext }));
}

/**
 * Updates to currentta - data to update for current user, data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} profile, signs out of current profile, signs in and creates anonymous user
 * @param {Object} da promise.
 */
export function updateToCurrentThenSignInAndCreateAnonymousProfile({
  data: { dataToUpdate, dataToCreate, signInAnonymouslyTracking },
  visitorAndPageContext,
}) {
  return updateProfileData({ data: dataToUpdate, visitorAndPageContext, method: IDM_METHODS.UPDATE })
    .then(() => signOut())
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile({ data: dataToCreate, visitorAndPageContext }));
}

/**
 * Save or user data in IDM in one request
 * @param {Object} deleteData - data to delete.
 * @param {Object} updateData - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function mixedUpdateProfileData({ data: { deleteData, updateData }, visitorAndPageContext }) {
  return Promise.all([
    deleteData && updateProfileData({ data: deleteData, method: IDM_METHODS.DELETE }),
    updateData && updateProfileData({ data: updateData, visitorAndPageContext, method: IDM_METHODS.UPDATE }),
  ]);
}
