import {
  transform,
  mapValues,
  sortedUniq,
  map,
  sortedUniqBy,
  sortBy,
  get,
  isEmpty,
  differenceWith,
  isEqual,
  maxBy,
  sumBy,
  isFinite,
  defaults,
  clone,
  isNumber,
} from 'lodash';
/* Utils */
import { mergePrimaryWithChecked } from 'site-modules/shared/utils/inventory/price-analysis';
import {
  getDefaultLeaseDownPayment,
  getValidationFormMessages,
} from 'site-modules/shared/utils/calculator/calculator-helper';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
/* Constants */
import {
  CONFLICTED_CONDITIONAL_INCENTIVE_MSG,
  INVALID_CONDITIONAL_INCENTIVE_MSG,
  DEFAULT_INFO_MSG,
  NEGATIVE_TRADE_IN_MSG,
  TOTAL_TRADE_IN_MSG,
  APPLIED_TRADE_IN_MSG,
  PRICE_ANALYSIS_LINK_DEFAULT,
  DEFAULT_LOAN_DOWN_PAYMENT,
  DEFAULT_ANNUAL_MILEAGE,
  DEFAULT_TERM,
  DEFAULT_TRADE_IN,
  DEFAULT_LEASE_ANNUAL_MILEAGE,
  DEFAULT_LEASE_TERM,
  CREDIT_SCORE_VALUES,
  CREDIT_SCORE_EXCELLENT,
  CREDIT_SCORE_POOR,
} from 'site-modules/shared/constants/calculator/calculator';
import {
  PREQUALIFIED_RECEIPT_ID,
  PURCHASE_PREQUALIFIED_RECEIPT_ID,
  PURCHASE_USED_PREQUALIFIED_RECEIPT_ID,
} from 'site-modules/shared/constants/car-buying/receipt-ids';
import {
  DEFAULT_CREDIT_SCORE,
  DEFAULT_USED_CREDIT_SCORE,
  LEASE,
  PURCHASE,
} from 'site-modules/shared/constants/car-buying';
import { getTotalRebates } from 'site-modules/shared/components/inventory/utils/get-total-rebates';

function sortAscending(a, b) {
  return a - b;
}

function mapTerms(annualMileage, key) {
  return mapValues(annualMileage, values =>
    key
      ? map(sortedUniqBy(sortBy(values, ['term']), item => item.term), item => ({ [key]: item.term }))
      : sortedUniq(map(values, 'term').sort(sortAscending))
  );
}

function getLeaseCalculatorConfig({ annualMileage }, key) {
  const termsMapping = mapTerms(annualMileage, key);

  const mileagesMapping = transform(
    termsMapping,
    // eslint-disable-next-line
    (result, values, mileageKey) => {
      values.forEach(value => {
        const resultKey = key ? value[key] : value;
        // eslint-disable-next-line no-param-reassign
        (result[resultKey] || (result[resultKey] = [])).push(key ? { [key]: Number(mileageKey) } : Number(mileageKey));
      });
    },
    {}
  );

  return {
    termsMapping,
    mileagesMapping,
  };
}

function getLoanCalculatorConfig({ annualMileage }, key) {
  return {
    termsMapping: mapTerms(annualMileage, key),
    mileagesMapping: {},
  };
}

export function getCalculatorConfig(digitalRetailTerms, { key, isPurchase }) {
  return isPurchase
    ? getLoanCalculatorConfig(digitalRetailTerms, key)
    : getLeaseCalculatorConfig(digitalRetailTerms, key);
}

/**
 * Gets applied purchase and conditional incentives.
 * @param {object} incentivesData
 * @returns {object[]}
 */
export function getAppliedIncentives(incentivesData) {
  const purchasePrimaryIncentives = get(incentivesData, 'incentives.primary', []).map(item => ({
    ...item,
    primary: true,
  }));

  const purchaseCheckedIncentives = get(incentivesData, 'checkedIncentives').map(incentive => ({
    ...incentive,
    checked: true,
  }));

  return mergePrimaryWithChecked(purchaseCheckedIncentives, purchasePrimaryIncentives).appliedIncentives;
}

export function getAppraisalMMYLabel({ make, model, year, tradeIn }) {
  const isLabelExist = typeof tradeIn === 'number' && make && model && year;
  return isLabelExist ? `${year} ${make} ${model}` : '';
}

/**
 * Checks if incentive is mutually exclusive from selected incentives.
 * @param {array} selectedIncentives
 * @param {object} incentive
 * @param {withPriceCheck} boolean
 * @returns {boolean}
 */
export function isIncentiveMutuallyExclusive(selectedIncentives, incentive, withPriceCheck = false) {
  const { incentiveTermRange, incentiveId } = incentive;

  if (withPriceCheck) {
    let incentiveCash = get(incentive, 'cash', 0);

    if (!isEmpty(incentiveTermRange)) {
      const customerCash = incentiveTermRange.map(term => term.customerCash);
      const maxCash = Math.max(...customerCash);
      incentiveCash = maxCash;
    }

    return selectedIncentives.some(
      ({ mutuallyExclusiveIncentiveIds = [], cash }) =>
        mutuallyExclusiveIncentiveIds.includes(incentiveId) && cash > incentiveCash
    );
  }

  return (
    !isEmpty(selectedIncentives) &&
    selectedIncentives.some(({ mutuallyExclusiveIncentiveIds = [] }) =>
      mutuallyExclusiveIncentiveIds.includes(incentiveId)
    )
  );
}

function isEligibleIncentive(incentives, id) {
  return incentives.some(incentive => incentive.incentiveId === id);
}

export function getInfoMessageForConditionaIncentives(
  incentiveId,
  prevAppliedIncentives,
  currentAppliedIncentives,
  selectedIncentives
) {
  const isPrevApplied = isEligibleIncentive(prevAppliedIncentives, incentiveId);
  const isCurrentlyApplied = isEligibleIncentive(currentAppliedIncentives, incentiveId);
  const isSelected = isEligibleIncentive(selectedIncentives, incentiveId);
  const isCurrentlyAndPrevAppliedIncentivesEqual =
    differenceWith(prevAppliedIncentives, currentAppliedIncentives, isEqual).length === 0;
  if (
    isSelected &&
    ((isCurrentlyAndPrevAppliedIncentivesEqual && !isCurrentlyApplied) ||
      (!isCurrentlyAndPrevAppliedIncentivesEqual && !isPrevApplied && !isCurrentlyApplied))
  ) {
    return INVALID_CONDITIONAL_INCENTIVE_MSG;
  }

  if (isSelected && !isCurrentlyAndPrevAppliedIncentivesEqual && isPrevApplied && !isCurrentlyApplied) {
    return CONFLICTED_CONDITIONAL_INCENTIVE_MSG;
  }
  return DEFAULT_INFO_MSG;
}

export function getInfoMessageForResultsLowerPayment(
  prevAppliedIncentives,
  currentAppliedIncentives,
  selectedIncentives
) {
  let infoMessage = DEFAULT_INFO_MSG;

  selectedIncentives.forEach(incentive => {
    const { incentiveId, conditionCategory } = incentive;
    const isPrevApplied = isEligibleIncentive(prevAppliedIncentives, incentiveId);
    const isCurrentlyApplied = isEligibleIncentive(currentAppliedIncentives, incentiveId);

    if (!isPrevApplied && isCurrentlyApplied) {
      infoMessage = `Based on the changes you made to your deal, the <span class="fw-bold"> ${conditionCategory} Incentive has been applied.</span>`;
    }
  });
  return infoMessage;
}

export function getTradeInValidationErrorMessage(tradeIn, message) {
  if (tradeIn < 0) {
    return message ? `${message} ${NEGATIVE_TRADE_IN_MSG}` : NEGATIVE_TRADE_IN_MSG;
  }

  return message || '';
}

export function filterOutPrimaryLoanIncentives(incentives, excludedIncentives) {
  return incentives.filter(
    incentive =>
      !excludedIncentives.filter(excludedIncentive => excludedIncentive.incentiveId === incentive.incentiveId).length
  );
}

export function getMaxRebatesIncentivesValue(incentives) {
  const maxRebateIncentive = maxBy(incentives, incentive => {
    const { cash, incentiveTermRange } = incentive;

    if (cash === 0 && !isEmpty(incentiveTermRange)) {
      return maxBy(incentiveTermRange, termRange => termRange.customerCash);
    }
    return incentive.cash;
  });

  const cash = get(maxRebateIncentive, 'cash', 0);
  const incentiveTermRange = get(maxRebateIncentive, 'incentiveTermRange', []);
  let maxRebateIncentiveValue = cash;

  if (cash === 0 && !isEmpty(incentiveTermRange)) {
    const customerCash = incentiveTermRange.map(term => term.customerCash);

    maxRebateIncentiveValue = Math.max(...customerCash);
  }
  return maxRebateIncentiveValue;
}

/**
 * Returns Trade-in details.
 * @param {object} selections
 * @param {object} limitCriteria
 * @param {number} appliedTradeIn
 * @param {string} pubState
 * @returns {object}
 */
export function getTradeInDetails({ selections, limitCriteria, appliedTradeIn, pubState }) {
  const { appraisalValue = 0, amountOwed = 0, make, model, year } = selections || {};

  let tradeInEquity = appraisalValue;
  let amountOwedValue = 0;

  // if make exist it means that user appraised vehicle
  if (make) {
    tradeInEquity = appraisalValue - amountOwed;
    amountOwedValue = amountOwed;
  }

  return {
    appraisalValue,
    amountOwed: amountOwedValue,
    appraisalVehicle: getAppraisalMMYLabel({ make, model, year, tradeIn: appraisalValue }),
    tradeInLimitDescription: `${APPLIED_TRADE_IN_MSG} ${
      getValidationFormMessages(limitCriteria, pubState).tradeInValidationError
    }`,
    tradeInEquityDescription: getTradeInValidationErrorMessage(tradeInEquity, TOTAL_TRADE_IN_MSG),
    appliedTradeInEquity: appliedTradeIn,
    tradeInEquity,
  };
}

export function getIncentivesSum(incentives) {
  return sumBy(incentives, 'cash') || sumBy(incentives, 'rebateAmount');
}

export function getReceiptId(isPurchase, isUsed) {
  if (isUsed) return PURCHASE_USED_PREQUALIFIED_RECEIPT_ID;
  else if (isPurchase) return PURCHASE_PREQUALIFIED_RECEIPT_ID;

  return PREQUALIFIED_RECEIPT_ID;
}

export function getPriceAnalysisLink(incentives, defaultString = PRICE_ANALYSIS_LINK_DEFAULT) {
  if (!isEmpty(incentives)) {
    const maxRebateValue = getMaxRebatesIncentivesValue(incentives);

    return maxRebateValue > 0
      ? `You may be eligible for an additional ${formatPriceString(maxRebateValue)} worth of incentives`
      : defaultString;
  }

  return defaultString;
}

/**
 * Returns value if it's finite, else returns default value.
 * @param value
 * @param [def='']
 * @return {number|*}
 */
export function getNumValueOrDefault(value, def = '') {
  return isFinite(value) ? value : def;
}

/**
 * Gets default calculator selections.
 * @return {object}
 */
export function getCalculatorSelections({ storedLeaseSelections, storedLoanSelections, isUsed, vehicle }) {
  return {
    [PURCHASE]: defaults(clone(storedLoanSelections || {}), {
      downPayment: DEFAULT_LOAN_DOWN_PAYMENT,
      annualMileage: DEFAULT_ANNUAL_MILEAGE,
      term: DEFAULT_TERM,
      tradeIn: DEFAULT_TRADE_IN,
      creditScore: isUsed ? DEFAULT_USED_CREDIT_SCORE : DEFAULT_CREDIT_SCORE,
    }),
    [LEASE]: defaults(clone(storedLeaseSelections || {}), {
      downPayment: getDefaultLeaseDownPayment(vehicle),
      annualMileage: DEFAULT_LEASE_ANNUAL_MILEAGE,
      term: DEFAULT_LEASE_TERM,
      tradeIn: DEFAULT_TRADE_IN,
      creditScore: DEFAULT_CREDIT_SCORE,
    }),
  };
}

export function getAdditionalData({
  isNonTMVDealer,
  incentives,
  purchasePriceAfterRebates,
  isHighESP,
  isPurchase,
  msrp,
}) {
  const hasIncentives =
    getTotalRebates({
      incentives,
      showLeasePayments: !isPurchase,
    }) > 0;
  const espText = isHighESP ? 'What others are paying is' : 'Edmunds suggests you pay';
  const espLabel = hasIncentives
    ? `${espText} ${formatPriceString(purchasePriceAfterRebates)} (includes incentives)`
    : `${espText} ${formatPriceString(purchasePriceAfterRebates)}`;

  return {
    label: isNonTMVDealer ? `MSRP is ${formatPriceString(msrp)}` : espLabel,
  };
}

/**
 * Returns limit price and benchmark price for digital retail calculator module.
 * @param {Object} vehicle
 * @param {Object} calculatedOffer
 * @param {boolean} isUsed
 * @returns {{sellingPrice: number, msrp: number}}
 */
export function getDigitalRetailCalcLimitPrices({ vehicle, calculatedOffer, isUsed }) {
  const displayPrice = get(vehicle, 'prices.displayPrice');
  const sellingPrice = get(calculatedOffer, 'sellingPrice');
  const totalRebate = get(calculatedOffer, 'totalRebate', 0);
  const sellingPriceAfterRebates = sellingPrice - totalRebate;

  return { sellingPrice: isUsed ? displayPrice : sellingPriceAfterRebates, msrp: displayPrice };
}

export function isCreditScoreValid(creditScore) {
  return (
    isNumber(creditScore) &&
    creditScore <= CREDIT_SCORE_VALUES[CREDIT_SCORE_EXCELLENT].max &&
    creditScore >= CREDIT_SCORE_VALUES[CREDIT_SCORE_POOR].min
  );
}
